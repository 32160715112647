.form {
  background-color: #d2f1fd;
  width: 50%;
  height: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.form input {
  width: 250px;
  height: 40px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000;
  box-shadow: 5px 5px 15px -2px #000000;
  border: none;
  border-radius: 15px;
  margin: 10px 0 10px 0;
  padding: 10px;
}

.form input:focus {
  outline: none;
  border: #000000 2px solid;
}

.form textarea {
  width: 250px;
  height: 100px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000;
  box-shadow: 5px 5px 15px -2px #000000;
  border: none;
  border-radius: 15px;
  margin: 10px 0 10px 0;
  padding: 10px;
}

.form textarea:focus {
  outline: none;
  border: #000000 2px solid;
}

.form input:hover,
textarea:hover {
  -webkit-box-shadow: 5px 5px 15px -2px #000000;
  box-shadow: 5px 5px 15px -2px #000000;
  border-radius: 15px;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.form button {
  width: 250px;
  height: 40px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000;
  box-shadow: 5px 5px 15px -2px #000000;
  border: none;
  border-radius: 15px;
  margin: 10px 0 10px 0;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
}

.form button:hover {
  -webkit-box-shadow: 5px 5px 15px -2px #000000;
  box-shadow: 5px 5px 15px -2px #000000;
  border-radius: 15px;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  color: #000000;
}

.form ::placeholder {
  text-align: center;
}

@media (max-width: 900px) {
  .form {
    height: 100%;
  }

  .form h2 {
    font-size: 2rem;
  }

  .form input {
    width: 100%;
  }

  .form textarea {
    width: 100%;
  }

  .form button {
    width: 100%;
    height: 50px;
    padding: 5px;
  }

  section h2 {
    font-size: 1.5rem;
  }
}
