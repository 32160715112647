.container {
  width: 100%;
  /* background-color: #fde4d6; */
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer h2 {
  font-size: 4rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

footer p {
  font-size: 0.8rem;
  font-weight: 400;
  color: grey;
  margin-bottom: 20px;
}

.social img {
  width: 75px;
  margin: 0 30px;
}

.social img:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.cv {
  font-size: 5rem;
  color: #000;
  font-weight: 700;
  text-decoration: none;
  margin-left: 10px;
}

.social a:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

/* RESPONSIVE */

@media (max-width: 900px) {
  footer h2 {
    font-size: 2rem;
  }
  footer p {
    font-size: 0.6rem;
  }

  .social img {
    width: 30px;
    margin: 0 10px;
  }
}
