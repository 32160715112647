.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  width: 100%;
  min-height: 100%;
  padding: 100px 0 100px 0;

  /* background-color: #ffebc5; */
}

.cardList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  width: 75%;
  height: 100%;

  /* min-height: 30vh; */
  padding-top: 50px;
}

section h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  .cardList {
    width: 100%;
  }

  .container {
    height: 100%;
    width: 100%;
  }

  section h2 {
    font-size: 2rem;
  }
}
