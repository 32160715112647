.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  width: 100%;
  /* background-color: #fde4d6; */
  height: 100vh;
}

header {
  color: black;
}

/* .title {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  width: 100%;
  font-size: 12rem;
  font-weight: 800;
} */

.logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 60%;
}

.arrow:hover {
  width: 65%;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

header p {
  margin-bottom: 20px;
}

.profil {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  width: 60%;
  padding-bottom: 30px;

  padding-top: 30px;
}

.subtitle {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  width: 60%;
  font-size: 1.5rem;
  padding-bottom: 100px;
}

.subtitle h1 {
  font-size: 1.5rem;
}

/* profil et subtitle apparaissenr après quelques secondes */
.profil {
  animation: appear 2.5s ease-in-out;
}

.subtitle {
  animation: appear 3s ease-in-out;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* RESPONSIVE */

@media (max-width: 900px) {
  .title {
    width: 100%;
    font-size: 3rem;
    padding-top: 20px;
  }
  .subtitle {
    width: 75%;
    font-size: 1rem;
  }

  .profil {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
    width: 70%;
  }

  .arrow {
    width: 40%;
  }

  .arrow:hover {
    width: 35%;
  }
}
