.cardListItem {
  font-family: "Assistant", sans-serif;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: stretch;
  width: 750px;
  height: 150px;

  -webkit-box-shadow: 5px 5px 15px -2px #000000;
  box-shadow: 5px 5px 15px -2px #000000;
  /* border-radius: 30px; */
  margin: 30px;
  padding: 20px;
  /* background: linear-gradient(145deg, #e6d4b1, #fffbd3); */
  background: white;
  border: 2px solid black;
  box-shadow: 4px 4px black;
  border-radius: 5px;
}

.cardListItem:hover {
  -webkit-box-shadow: 5px 5px 15px -2px #000000;
  box-shadow: 5px 5px 15px -2px #000000;
  /* border-radius: 30px; */
  margin: 30px;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  background-color: #d2f1fd;
}

.aCard {
  text-decoration: none;
  color: #000000;
}

.aCard h2 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

/* faire apparaitre les cardListItem lorsqu'on arrive sur le bon endroit de la page au scroll */

.cardListItem {
  animation: cardListItem 2s ease-in-out;
}

@keyframes cardListItem {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* RESPONSIVE */

@media (max-width: 900px) {
  .cardListItem {
    width: 350px;
    height: 150px;
  }
  .cardListItem h2 {
    font-size: 1.2rem;
  }
  .cardListItem p {
    font-size: 0.85rem;
  }
}
