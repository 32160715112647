* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: "Assistant", sans-serif;
  background: linear-gradient(
    288deg,
    #ffb6b9 0%,
    #ffb6b9 35%,
    #fae3d9 calc(35% + 1px),
    #fae3d9 45%,
    #bbded6 calc(45% + 1px),
    #bbded6 65%,
    #61c0bf calc(65% + 1px),
    #61c0bf 100%
  );
}
