.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 500px;
  background-color: #d2f1fd;
  width: 100%;
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;

  padding: 20px;
}

.sign {
  width: 250px;
  height: 40px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000;
  box-shadow: 5px 5px 15px -2px #000000;
  border: none;
  border-radius: 15px;
  margin: 10px 0 10px 0;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
}

.sign:hover {
  background-color: #ffffff;
  color: #000000;
}

.user img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.user h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

@media screen and (max-width: 900px) {
  .user {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container {
    flex-direction: column;
    height: 1000px;
    width: 100%;
  }

  .sign {
    width: 70%;
  }
}
